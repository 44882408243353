export enum ModalMode {
  signup = 'signup',
  login = 'login',
  extraUserData = 'extraUserData',
  unset = '',
}

export interface AuthFormValue {
  username: string;
  email: string;
  password: string;
  twoFactorAuthCode: string;
  recaptchaValue?: string | null;
  agreeTerms: boolean;
  isTranslator: boolean;
  userType: string;
  userCountry: string;
  captchaService?: string;
}
