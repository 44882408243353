import {FC, useCallback, useMemo} from 'react';

import NextLink from 'next/link';
import {usePathname} from 'next/navigation';
import styled from 'styled-components';

import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import BaseLink from '@/src/components/BaseComponents/BaseLink';
import {SubMenuItem} from '@/src/components/GeneralComponents/Sidebar/SidebarUtils';
import useMobile from '@/src/hooks/useMobile';
import {font_large} from '@/theme/fonts';

interface SidebarSubMenuProps {
  item: SubMenuItem;
  openSideBar: (key?: string) => void;
}

const SidebarSubMenuItem: FC<SidebarSubMenuProps> = ({item: {key, name, to, icon, href, target}, openSideBar}) => {
  const pathname = usePathname();
  const hash = typeof window !== undefined ? window.location.hash : '';
  const {isTablet} = useMobile();

  const getDataAttr = useMemo(() => (str: string) => (str.includes('#') ? str.substring(str.indexOf('#')) : str), []);

  const handleClickMenu = useCallback(() => {
    isTablet && openSideBar();
  }, [isTablet, openSideBar]);

  if (key === 'community') {
    return null;
  } else {
    return (
      <Wrapper
        key={key}
        className={to === `${pathname?.replace(/\/\s*$/, '')}${hash}` ? 'active-item' : ''}
        onClick={handleClickMenu}
      >
        <SubIcon icon={icon} boxW={20} boxH={20} />
        {to && (
          <Link href={to} passHref key={key} data-qa-auto={getDataAttr(to)}>
            {name}
          </Link>
        )}
        {!to && href && (
          <ExternalLink key={key} href={href} target={target ?? ''}>
            {name}
          </ExternalLink>
        )}
      </Wrapper>
    );
  }
};

const LinkStyle = `
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const ExternalLink = styled(BaseLink)`
  ${LinkStyle};
  ${font_large};
  color: ${({theme}) => theme.colors.grey090};
`;

const InternalLink = styled.a<{$selected?: boolean}>`
  ${LinkStyle};
  ${font_large};
  color: ${({theme}) => theme.colors.grey090};
  color: ${({theme, $selected}) => ($selected ? theme.colors.blue100 : theme.colors.grey090)};
`;

const Icon = styled(BaseIcon)<{$selected?: number}>`
  width: 20px;
  height: 20px;
  fill: ${({
    theme: {
      colors: {grey090, blue100},
    },
    $selected,
  }) => ($selected ? blue100 : grey090)};
`;

const Link = styled(NextLink)`
  ${LinkStyle};
  ${font_large};
  color: ${({theme}) => theme.colors.grey090};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 20px 7px 40px;
  text-decoration: none;
  color: ${({theme}) => theme.colors.grey090};

  ${InternalLink}, ${ExternalLink} {
    ${font_large};
    font-weight: normal;
    color: ${({theme}) => theme.colors.grey090};

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  &:last-child {
    padding-bottom: 14px;
  }

  &.active,
  &.active-item {
    ${InternalLink},
    ${Link} {
      color: ${({theme}) => theme.colors.blue100};
    }

    ${Icon} {
      fill: ${({theme}) => theme.colors.blue100};
    }
  }
`;

const SubIcon = styled(Icon)`
  margin-right: 10px;
  fill: ${({theme}) => theme.colors.grey080};
`;

export default SidebarSubMenuItem;
