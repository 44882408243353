import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {UserData} from '@/src/interfaces/types/user.types';

interface UserState {
  user: UserData | null;
}

const initialState: UserState = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<UserState['user']>) {
      state.user = action.payload;
    },
  },
});

export const {setUserData} = userSlice.actions;

export default userSlice.reducer;
