import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import styled from 'styled-components';

import Arrow from '@/assets/icons_refactor/Common/long-arrow.svg';
import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import {ModalMode} from '@/src/interfaces/types/auth.types';

import Consts from '../../../AuthModalConsts';

const {FORGOT_PASSWORD, SIGN_UP, WITHOUT_ACCOUNT} = Consts;

const {signup} = ModalMode;
interface Props {
  handleOpenModal: (type: ModalMode) => void;
  email?: string;
}

const Footer = ({handleOpenModal, email}: Props) => (
  <Wrapper>
    <Link href={`/auth/resetPassword${email ? '?email=' + email : ''}`} target="__blank" rel="noopener noreferrer">
      {FORGOT_PASSWORD}
    </Link>

    <SwitchBox>
      {WITHOUT_ACCOUNT}
      <Button data-qa-auto="switch-to-signup-mode" onClick={() => handleOpenModal(signup)} disableRipple>
        {SIGN_UP}
        <Icon icon={Arrow} />
      </Button>
    </SwitchBox>
  </Wrapper>
);

const Wrapper = styled.div`
  font-size: 0.9rem;
  color: ${({theme}) => theme.colors.green084};
  text-align: center;
  font-weight: 300;

  & a,
  & button {
    padding: 0;
    color: ${({theme}) => theme.colors.blue093};
    vertical-align: baseline;
    text-decoration: none;

    &:hover {
      color: ${({theme}) => theme.colors.blue093};
      background-color: transparent;
      text-decoration: underline;
    }
  }
`;

const Button = styled(BaseButton)`
  margin-left: 5px;
`;

const SwitchBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({theme}) => theme.colors.green084};
`;

const Icon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.blue093};
  margin-left: 2px;
`;

export default Footer;
