import {MouseEvent, useState} from 'react';

import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import styled from 'styled-components';

import Leave from '@/assets/icons_refactor/BlendTalk/leave.svg';
import Search from '@/assets/icons_refactor/Client/search.svg';
import Integration from '@/assets/icons_refactor/SideMenu/integration.svg';
import Software from '@/assets/icons_refactor/SideMenu/software.svg';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import {useSession} from '@/src/hooks/useSession';
import useUserData from '@/src/hooks/useUserData';

import Consts from '../HeaderConsts';

const {
  LOG_OUT,
  PANEL,
  SEARCH,
  DATA_QA: {ADMIN_HEADER_SEARCH, ADMIN_HEADER_LOGOUT, ADMIN_HEADER_C_PANEL},
} = Consts;

interface MenuHeaderOptions {
  onSearch: VoidFunction;
  onCpanel: VoidFunction;
  onLogout: VoidFunction;
}

const menuHeaderOptions = ({onSearch, onCpanel, onLogout}: MenuHeaderOptions) => [
  {
    item: SEARCH,
    icon: (
      <span data-qa-auto={ADMIN_HEADER_SEARCH}>
        <BaseIcon icon={Search} />
      </span>
    ),
    action: onSearch,
  },
  {
    item: PANEL,
    icon: (
      <span data-qa-auto={ADMIN_HEADER_C_PANEL}>
        <BaseIcon icon={Integration} />
      </span>
    ),
    action: onCpanel,
  },
  {
    item: LOG_OUT,
    icon: (
      <span data-qa-auto={ADMIN_HEADER_LOGOUT}>
        <BaseIcon icon={Leave} />
      </span>
    ),
    action: onLogout,
  },
];

const AdminHeaderDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const session = useSession();
  const {handleLogout} = useUserData();

  const onSearch = () => (typeof window !== undefined ? (window.location.href = '/admin') : null);
  const onCpanel = () => (typeof window !== undefined ? (window.location.href = '/cpanel') : null);
  const onLogout = () => session?.CSRFToken && handleLogout(session?.CSRFToken);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;
  const id = open ? 'admin-header-popover' : undefined;

  return (
    <>
      <Wrapper onClick={handleClick}>
        <BaseIcon icon={Software} boxH={20} boxW={18} />
      </Wrapper>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableScrollLock
      >
        <MenuItemWrapper>
          {menuHeaderOptions({onSearch, onCpanel, onLogout}).map(({item, action, icon}) => (
            <Item key={item} onClick={action}>
              {icon}
              {item}
            </Item>
          ))}
        </MenuItemWrapper>
      </StyledPopover>
    </>
  );
};

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.colors.grey000};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease;
  margin-left: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.5s ease;
  }
`;

const MenuItemWrapper = styled.div`
  padding: 8px;
  box-sizing: border-box;
`;

const Item = styled(MenuItem)`
  background-color: ${({theme}) => theme.colors.grey000};
  font-size: 16px;
  color: ${({theme}) => theme.colors.grey100};
  min-width: 180px;
  column-gap: 10px;

  &:hover {
    background-color: ${({theme}) => theme.colors.grey030};
  }
`;

export default AdminHeaderDropdown;
