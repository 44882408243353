import {createTheme, ThemeOptions} from '@mui/material/styles';
import {TypographyOptions} from '@mui/material/styles/createTypography';

import MainTheme from '@/theme';

import {ThemeBreakpoints, ThemeColors} from './index';

declare module '@mui/material/styles' {
  interface Theme {
    colors: ThemeColors;
    breakpoints: ThemeBreakpoints;
  }

  interface ThemeOptions {
    colors: ThemeColors;
    breakpoints: ThemeBreakpoints;
  }
}

const theme = createTheme({
  colors: MainTheme.colors,
  breakpoints: MainTheme.breakpoints as ThemeBreakpoints,
  typography: {
    fontFamily: 'Atten New',
    button: {
      textTransform: 'none',
      fontFamily: 'Atten New',
    },
  } as TypographyOptions,
  palette: {
    primary: {
      main: MainTheme.colors.blue100,
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '17.6px',
          fontWeight: 700,
        },
      },
    },
  },
} as ThemeOptions);

export default theme;
