import React, {CSSProperties, forwardRef} from 'react';

import {Link, LinkProps} from 'react-router-dom';
import styled from 'styled-components';

interface CustomLinkProps extends LinkProps {
  style?: CSSProperties;
}

const CustomRouterLink = forwardRef<HTMLDivElement, CustomLinkProps>((props, ref) => (
  <Wrapper ref={ref}>
    <Link {...props} />
  </Wrapper>
));

CustomRouterLink.displayName = 'CustomRouterLink';

const Wrapper = styled.div`
  flex-grow: 1;
`;

export default CustomRouterLink;
