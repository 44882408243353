import {FC, useState} from 'react';

import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import useUserData from '@/src/hooks/useUserData';
import {ModalMode} from '@/src/interfaces/types/auth.types';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import ConstTheme from '@/theme';

import AuthSocial from '../../AuthSocial';
import Title from '../../Title';

const Form = dynamic(() => import('./Form'), {ssr: false});

const {extraUserData} = ModalMode;

interface Props {
  mode: ModalMode;
  handleCloseModal: VoidFunction;
  needSocialButtons?: boolean;
}

const ExtraUserDataModal: FC<Props> = ({mode, handleCloseModal, needSocialButtons = false}) => {
  const isMobile = useMediaQuery(ConstTheme.breakpoints.maxSm);
  const {isFetchingAuth} = useUserData();

  const {data: userData} = useGetUserDataQuery();

  const [emailInput, setCurrentEmail] = useState<string>('');

  return (
    <DialogItem
      onClose={handleCloseModal}
      aria-labelledby="auth-dialog-title"
      fullWidth={isMobile}
      open={mode === extraUserData}
      disableEscapeKeyDown={isFetchingAuth}
    >
      <Title id="auth-dialog-title" onClose={handleCloseModal} disableClose={isFetchingAuth}>
        {`Hi ${userData?.name || ''}`}
      </Title>
      <ModalContent>
        {needSocialButtons && <AuthSocial />}
        <Form emailUpdatedHandler={setCurrentEmail} emailParam={emailInput} />
      </ModalContent>
    </DialogItem>
  );
};

const ModalContent = styled(MuiDialogContent)`
  padding: 8px 30px;

  @media (max-width: 480px) {
    padding: 8px 20px;
  }
`;

const DialogItem = styled(Dialog)`
  &.MuiDialog-paper {
    overflow: visible;
    max-width: 560px;
    border-radius: 0.75rem;

    @media ${({theme}) => theme.breakpoints.maxSm} {
      margin: 10px;
      width: 100%;
    }
  }
`;
export default ExtraUserDataModal;
