import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ConfigDataFromGet} from '@/src/types/Wizard/types';

interface ConfigState {
  data: ConfigDataFromGet['Config'] | null;
}

const initialState: ConfigState = {
  data: null,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfigData(state, action: PayloadAction<ConfigDataFromGet['Config'] | null>) {
      state.data = action.payload;
    },
  },
});

export const {setConfigData} = configSlice.actions;

export default configSlice.reducer;
