import {FC, ReactNode, useMemo, useState} from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import {toast} from 'react-toastify';
import styled from 'styled-components';

import CustomRouterLink from '@/src/components/GeneralComponents/Sidebar/components/SidebarItem/components/CustomRouterLink';
import {iconsMap} from '@/src/components/GeneralComponents/Sidebar/SidebarUtils';
import {useAppSelector} from '@/src/lib/store/hooks';

import CONSTS from '../../SidebarConsts';

const {CONFIRM_TEXT} = CONSTS;

const showConfirm = () => {
  toast.warn(CONFIRM_TEXT, {
    position: 'top-right',
  });
};

interface Props {
  title: string;
  href?: string;
  depth: number;
  children?: ReactNode;
  icon: string;
  isOpen?: boolean;
  label: ReactNode;
}

const Index: FC<Props> = ({title, href, depth, children, icon, isOpen, label}) => {
  const [open, setOpen] = useState<boolean>(!!isOpen);

  const currentHash = typeof window !== undefined ? window.location.hash : '';

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const MenuIcon = iconsMap[icon];

  const {profileData} = useAppSelector((state) => state.profile);

  const translatorGuidelines = profileData?.translator_guidelines;

  console.log(profileData, 'profileData - IDK');

  const isGuidLines = useMemo(() => currentHash === '#translator_guidelines', [currentHash]);

  const isActive = useMemo(() => (currentHash ? href === currentHash : href === '#account'), [currentHash, href]);

  const getButtonPadding = useMemo(() => (depth > 0 ? 37 * depth : 5), [depth]);

  if (children) {
    return (
      <ListItem disableGutters data-autotest-attr={href}>
        <ButtonItem color="primary" onClick={handleToggle} extrapadding={getButtonPadding}>
          {MenuIcon && <MenuIcon className="app-sidebar-icon" />}
          <span>{title}</span>
          <ExpandLessIcon
            className={`sidebar-expand-icon ${!open ? 'sidebar-expand-icon-rotate' : ''}`}
            color="inherit"
          />
        </ButtonItem>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem disableGutters data-autotest-attr={href}>
        <ButtonItem
          activeClassName="active-item"
          color="primary"
          disableRipple
          variant="text"
          isActive={isActive}
          component={CustomRouterLink}
          exact
          extrapadding={getButtonPadding}
          {...(isGuidLines && translatorGuidelines?.shouldAcceptTermsOfService && {onClick: showConfirm})}
          to={isGuidLines && translatorGuidelines?.shouldAcceptTermsOfService ? currentHash : href}
        >
          {MenuIcon && <MenuIcon className="app-sidebar-icon" />}
          {title}
          {href === '#translator_guidelines' && translatorGuidelines?.shouldAcceptTermsOfService && (
            <span className="warning-icon">&nbsp;*</span>
          )}
          {label && <span className="menu-item-label">{label}</span>}
        </ButtonItem>
      </ListItem>
    );
  }
};

const ButtonItem = styled(Button)<{extrapadding: number}>`
  padding-left: ${({extrapadding}) => extrapadding}px;
`;

export default Index;
