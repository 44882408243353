export default {
  SEARCH: 'Admin Search',
  PANEL: 'Control Panel',
  LOG_OUT: 'Log out',
  DATA_QA: {
    ADMIN_HEADER_SEARCH: 'qa-admin-search-from-header-menu',
    ADMIN_HEADER_LOGOUT: 'qa-admin-logout-from-header-menu',
    ADMIN_HEADER_C_PANEL: 'qa-admin-c-panel-from-header-menu',
  },
  NAV_MENU_TYPES: {
    LINK: 'link',
    DROPDOWN: 'dropdown',
  },
};
