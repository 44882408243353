export default {
  LOGIN_TITLE: 'Sign In',
  LOGIN: 'Log in',
  SIGN_UP: 'Sign up',
  FORGOT_PASSWORD: 'Forgot your password?',
  WITHOUT_ACCOUNT: 'Don&#39;t have an account?',
  CREATE_ACC: 'Create your account ',
  TERMS: 'Terms of Use',
  CREATE_ACCOUNT_TITLE: 'By creating an account, you agree to',
  PRIVACY: 'Privacy Policy',
  HAVE_ACC: 'Already have an account? ',
};
