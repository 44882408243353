'use client';

import {FC, useRef} from 'react';

import {Provider} from 'react-redux';

import {AppStore, makeStore} from '@/src/lib/store/store';

import {PropsWithPreloadedState} from '../interfaces/types/layout';

const StoreProvider: FC<PropsWithPreloadedState> = ({children, preloadedState}) => {
  const storeRef = useRef<AppStore>();

  if (!storeRef.current) {
    storeRef.current = makeStore(preloadedState);
  }

  return <Provider store={storeRef.current}>{children}</Provider>;
};

export default StoreProvider;
