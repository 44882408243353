import {FC} from 'react';

import styled from 'styled-components';

import BaseLink from '@/src/components/BaseComponents/BaseLink';
import useConfig from '@/src/hooks/useConfig';
import {font_medium} from '@/theme/fonts';

const currentYear = new Date().getFullYear();

const Footer: FC = () => {
  const config = useConfig();

  return (
    <Wrapper>
      <Text>
        2008 - {currentYear} All rights reserved {config?.companyName}
        <sup>TM</sup>
      </Text>
      <Text>
        &nbsp;
        <BaseLink href={`https://${config?.blendDomainURL}/terms-and-conditions/`}>Terms & conditions</BaseLink>
        &nbsp;and&nbsp;
        <BaseLink href={`https://${config?.blendDomainURL}/privacy-policy/`}>Privacy Policy</BaseLink>
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  ${font_medium};
  height: 29px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({theme}) => theme.colors.grey080};
  border-top: 1px solid ${({theme}) => theme.colors.grey050};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({theme}) => theme.colors.grey000};
  z-index: 8;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    height: 50px;
    flex-direction: column;
  }
`;

const Text = styled.div`
  ${font_medium};
  height: 21px;

  sup {
    ${font_medium};
    font-size: 10.5px;
  }
`;

export default Footer;
