import {FC, ReactNode} from 'react';

import {Person} from '@mui/icons-material';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChatIcon from '@mui/icons-material/ChatOutlined';
import CodeIcon from '@mui/icons-material/Code';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import GradeTwoTone from '@mui/icons-material/GradeTwoTone';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import MailIcon from '@mui/icons-material/MailOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import {NextRouter} from 'next/router';

import Chat from '@/assets/icons_refactor/Client/chat.svg';
import Profile from '@/assets/icons_refactor/SideMenu/about-me.svg';
import SideAccountInfo from '@/assets/icons_refactor/SideMenu/account-info.svg';
import SideAvailability from '@/assets/icons_refactor/SideMenu/availability.svg';
import SideCertificates from '@/assets/icons_refactor/SideMenu/certificate.svg';
import SideCredits from '@/assets/icons_refactor/SideMenu/credits.svg';
import ExternalAccounts from '@/assets/icons_refactor/SideMenu/external-accounts.svg';
import SideGuidlines from '@/assets/icons_refactor/SideMenu/guidlines.svg';
import Integration from '@/assets/icons_refactor/SideMenu/integration.svg';
import SideKey from '@/assets/icons_refactor/SideMenu/key.svg';
import Keys from '@/assets/icons_refactor/SideMenu/keys.svg';
import SideLanguageSkills from '@/assets/icons_refactor/SideMenu/language.svg';
import SideLock from '@/assets/icons_refactor/SideMenu/lock.svg';
import SideLogin from '@/assets/icons_refactor/SideMenu/login.svg';
import Management from '@/assets/icons_refactor/SideMenu/management.svg';
import SideBalance from '@/assets/icons_refactor/SideMenu/round-statement.svg';
import Security from '@/assets/icons_refactor/SideMenu/security.svg';
import sideAvailableIcon from '@/assets/icons_refactor/SideMenu/side-available.svg';
import BlendTalk from '@/assets/icons_refactor/SideMenu/side-chat.svg';
import SideCommunity from '@/assets/icons_refactor/SideMenu/side-community.svg';
import Control from '@/assets/icons_refactor/SideMenu/side-control.svg';
import SideDashboard from '@/assets/icons_refactor/SideMenu/side-dashboard.svg';
import sideProjectsIcon from '@/assets/icons_refactor/SideMenu/side-doc.svg';
import {default as SideBilling, default as SideInvoices} from '@/assets/icons_refactor/SideMenu/side-invoices.svg';
import SideMessages from '@/assets/icons_refactor/SideMenu/side-post.svg';
import SideReference from '@/assets/icons_refactor/SideMenu/side-reference.svg';
import Settings from '@/assets/icons_refactor/SideMenu/side-settings.svg';
import SideStatement from '@/assets/icons_refactor/SideMenu/side-statement.svg';
import SideSupport from '@/assets/icons_refactor/SideMenu/side-support.svg';
import SideSoftware from '@/assets/icons_refactor/SideMenu/software.svg';
import SideExpertise from '@/assets/icons_refactor/SideMenu/star.svg';
import Support from '@/assets/icons_refactor/SideMenu/support.svg';
import SideTelegram from '@/assets/icons_refactor/SideMenu/telegram.svg';
import SidebarMenuList from '@/src/components/GeneralComponents/Sidebar/components/SidebarItem/components/SidebarMenuList';
import {USER_ROLES} from '@/src/types/Wizard/types';

import SidebarMenuListItem from './components/SidebarItem/components/SidebarMenuListItem';

export interface SubMenuItem {
  key: string;
  name: string;
  href?: string;
  to?: string;
  userTypes?: string[];
  icon?: FC;
  type?: string;
  target?: string;
}

export interface MainMenuItem {
  key: string;
  name: string;
  type?: string;
  icon?: FC;
  to?: string;
  href?: string;
  userTypes?: string[];
  submenu?: SubMenuItem[];
  iconComponent?: ReactNode;
  invisible?: boolean;
}

export const controlPanelLinks: SubMenuItem[] = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    to: '/dashboard',
    icon: SideDashboard,
  },
  {
    key: 'invoices',
    name: 'Invoices',
    to: '/member/invoices',
    icon: SideInvoices,
    userTypes: ['Admin'],
  },
  {
    key: 'balance',
    name: 'My Balance',
    to: '/balance',
    userTypes: ['Translator'],
    icon: SideBalance,
  },
  {
    key: 'statement',
    name: 'My Statements',
    to: '/statement',
    icon: SideStatement,
  },
  {
    key: 'messages',
    name: 'Messages',
    to: '/mbox',
    icon: SideMessages,
  },
  {
    key: 'community',
    name: 'Community',
    href: 'https://forum.getblend.com/',
    icon: SideCommunity,
  },
  {
    key: 'credits',
    name: 'Buy more credits',
    href: '/payment/quote/?amount=10000',
    userTypes: ['Admin'],
    icon: SideCredits,
  },
];

export const ProfileSubMenu: SubMenuItem[] = [
  {
    key: 'accountInformation',
    name: 'Account Information',
    to: '/profile#account',
    userTypes: ['Customer', 'Translator'],
    icon: SideAccountInfo,
  },
  {
    key: 'languages',
    name: 'Language Skills',
    to: '/profile#languages',
    icon: SideLanguageSkills,
  },
  {
    key: 'expertise',
    name: 'Expertise',
    to: '/profile#expertise',
    icon: SideExpertise,
  },
  {
    key: 'uploads',
    name: 'Resume & Certificates',
    to: '/profile#uploads',
    icon: SideCertificates,
  },
  {
    key: 'time',
    name: 'Availability',
    to: '/profile#time',
    icon: SideAvailability,
  },
  {
    key: 'references',
    name: 'References',
    to: '/profile#references',
    icon: SideReference,
  },
  {
    key: 'software_services',
    name: 'Software & Services',
    to: '/profile#software_services',
    icon: SideSoftware,
  },
  {
    key: 'mailing',
    name: 'Mailing Preferences',
    to: '/profile#mailing',
    icon: SideMessages,
  },
  {
    key: 'translator_guidelines',
    name: 'Translator Guidelines',
    to: '/profile#translator_guidelines',
    icon: SideGuidlines,
  },
  {
    key: 'telegram',
    name: 'Telegram Notifications',
    to: '/profile#telegram',
    icon: SideTelegram,
  },
  {
    key: 'tfa_enable',
    name: 'Enable 2 step login',
    to: '/profile#tfa_enable',
    icon: SideLogin,
  },
  {
    key: 'tfa_disable',
    name: 'Disable 2 step login',
    to: '/profile#tfa_disable',
    icon: SideLogin,
  },
  {
    key: 'password',
    name: 'Password Update',
    to: '/profile#password',
    icon: SideKey,
  },
  {
    key: 'privacy_policy',
    name: 'Privacy Policy',
    to: '/profile#privacy_policy',
    icon: SideLock,
  },
  {
    key: 'beneficiary_account',
    name: 'Sub-Accounts',
    to: '/profile#sub_account',
    icon: SideCommunity,
  },
];

export const translationLinks: SubMenuItem[] = [
  {
    key: 'ourPartners',
    name: 'Partners & Integrations',
    href: 'https://www.getblend.com/integrations/',
    target: '_blank',
    userTypes: ['Customer'],
    icon: Integration,
  },
  {
    key: 'apiDocumentation',
    name: 'API Documentation',
    href: 'https://apidocs.getblend.com/',
    target: '_blank',
    userTypes: ['Customer'],
    icon: SideSoftware,
  },
  {
    key: 'apikeys',
    name: 'API Keys',
    to: '/profile#apikeys',
    icon: Keys,
  },
];

export const MyTeamSubMenu: SubMenuItem[] = [
  {
    key: 'myTeam',
    name: 'MyTeam',
    href: '/myteam',
    icon: SideCommunity,
  },
];

export const SupportSubMenu: SubMenuItem[] = [
  {
    key: 'contactSupport',
    name: 'Contact support',
    href: 'https://help.getblend.com/hc/en-us/requests/new',
    target: '_blank',
    icon: Support,
  },
  {
    key: 'helpCenter',
    name: 'Help Center',
    href: 'https://help.getblend.com/hc/en-us',
    target: '_blank',
    icon: SideGuidlines,
  },
];

export const translationManagmentSubmenu: SubMenuItem[] = [
  {
    key: 'custom_notes',
    name: 'Manage Project Notes',
    to: '/profile#custom_notes',
    icon: SideReference,
  },
];

export const financialsSubmenu: SubMenuItem[] = [
  {
    key: 'invoices',
    name: 'Invoices',
    to: '/member/invoices',
    userTypes: ['Customer', 'Admin'],
    icon: SideInvoices,
  },
  {
    key: 'statements',
    name: 'Statements',
    to: '/customer-statement',
    userTypes: ['Customer', 'Admin'],
    icon: SideStatement,
  },
  {
    key: 'credits',
    name: 'Buy more credits',
    href: '/payment/quote/?amount=10000',
    userTypes: ['Customer', 'Admin'],
    icon: SideCredits,
  },
];

export const customerProfileSubmenu: SubMenuItem[] = [
  {
    key: 'accountInformation',
    name: 'Account Information',
    to: '/profile#account',
    userTypes: ['Customer', 'Translator'],
    icon: SideAccountInfo,
  },
  {
    key: 'billing',
    name: 'Billing Address',
    to: '/profile#billing',
    icon: SideBilling,
  },
  {
    key: 'mailing',
    name: 'Mailing Preferences',
    to: '/profile#mailing',
    icon: SideMessages,
  },
  {
    key: 'beneficiary_account',
    name: 'Sub Accounts',
    to: '/profile#sub_account',
    icon: ExternalAccounts,
  },
  {
    key: 'messages',
    name: 'Messages',
    to: '/mbox',
    icon: Chat,
  },
];

export const customerSecuritySubmenu: SubMenuItem[] = [
  {
    key: 'tfa_enable',
    name: 'Enable 2 step login',
    to: '/profile#tfa_enable',
    icon: SideLogin,
  },
  {
    key: 'tfa_disable',
    name: 'Disable 2 step login',
    to: '/profile#tfa_disable',
    icon: SideLogin,
  },
  {
    key: 'password',
    name: 'Password Update',
    to: '/profile#password',
    icon: SideKey,
  },
  {
    key: 'privacy_policy',
    name: 'Privacy Policy',
    to: '/profile#privacy_policy',
    icon: SideLock,
  },
];

export const getLinkColor = (selected?: number) => (selected ? 'blue100' : 'grey090');

export const iconsMap: Record<string, typeof SvgIcon> = {
  BarChartIcon,
  CalendarTodayIcon,
  ChatIcon,
  CodeIcon,
  DashboardIcon,
  ErrorIcon,
  FolderIcon,
  DashboardTwoToneIcon,
  GradeTwoTone,
  ListAltIcon,
  LockOpenIcon,
  MailIcon,
  PresentToAllIcon,
  PeopleIcon,
  PersonOutlineOutlinedIcon,
  ReceiptIcon,
  SettingsIcon,
  ViewModuleIcon,
};

const {admin, customer, translator} = USER_ROLES;

export const getNavItems = (
  myTeamStatus: boolean,
  isShowBlendTalkFeature: boolean,
  isMyTeamMenuItem: boolean,
  userType: string
): Record<string, MainMenuItem[]> => ({
  customer: [
    {
      key: 'dashboard',
      name: 'Dashboard',
      to: '/cpanel',
      icon: Control,
    },
    {
      key: 'projects',
      name: 'My projects',
      to: '/my-projects/customer/',
      icon: sideProjectsIcon,
    },
    {
      key: 'translationManagement',
      name: 'Translation Management',
      type: 'loggedIn',
      icon: Management,
      submenu: (myTeamStatus ? MyTeamSubMenu : []).concat(translationManagmentSubmenu),
    },
    {
      key: 'financials',
      name: 'Financials',
      type: 'loggedIn',
      icon: SideBalance,
      submenu: financialsSubmenu,
    },
    {
      key: 'translationSettings',
      name: 'Translation Settings',
      icon: Settings,
      submenu: translationLinks,
    },
    {
      key: 'blendTalk',
      name: 'BLENDTalk',
      type: 'loggedIn',
      to: '/blend-talk/chat/',
      icon: BlendTalk,
      userTypes: isShowBlendTalkFeature ? [customer, translator, admin] : [admin],
    },
    {
      key: 'profile',
      name: 'Profile',
      icon: Profile,
      submenu: customerProfileSubmenu,
    },
    {
      key: 'security',
      name: 'Security',
      icon: Security,
      submenu: customerSecuritySubmenu,
    },
    {
      key: 'support',
      name: 'Support',
      type: 'all',
      icon: SideSupport,
      submenu: SupportSubMenu,
    },
  ],
  translator: [
    {
      key: 'controlPanel',
      name: 'Control panel',
      icon: Control,
      type: 'loggedIn',
      submenu: controlPanelLinks,
    },
    {
      key: 'availableProjects',
      name: 'Open projects',
      href: '/open-project-freelance-translator',
      userTypes: [translator],
      icon: sideAvailableIcon,
    },
    {
      key: 'projects',
      name: 'My projects',
      href: userType === admin ? '/my-projects/customer/' : '/my-projects/translator/',
      userTypes: [admin, translator],
      icon: sideProjectsIcon,
    },
    {
      key: 'my-team',
      name: 'MyTeam',
      to: '/my-team',
      type: 'loggedIn',
      icon: SideCommunity,
      userTypes: [translator, customer, admin],
      invisible: !isMyTeamMenuItem,
    },
    {
      key: 'blendTalk',
      name: 'BLENDTalk',
      type: 'loggedIn',
      to: '/blend-talk/chat/',
      icon: BlendTalk,
      userTypes: isShowBlendTalkFeature ? [customer, translator, admin] : [admin],
    },
    {
      key: 'profile',
      name: 'Profile',
      icon: Person,
      submenu: ProfileSubMenu,
    },
    {
      key: 'support',
      name: 'Support',
      type: 'all',
      icon: SideSupport,
      submenu: SupportSubMenu,
    },
  ],
});

interface ChildRoutesProps {
  router?: NextRouter;
  items: ReactNode[];
  page: ChildPageRoutesProps;
  depth: number;
}

export interface ChildPageRoutesProps {
  content: ChildRoutesProps[];
  icon: string;
  to: string;
  badge: ReactNode;
  label: string;
}

export const reduceChildRoutes = ({router, items, page, depth}: ChildRoutesProps) => {
  const open = page.content ? router?.pathname?.includes(page.to) : false;

  const newItem = page.content ? (
    <SidebarMenuListItem
      depth={depth}
      icon={page.icon}
      key={page.to}
      label={page.badge}
      isOpen={open}
      title={page.label}
    >
      <div className="sidebar-menu-children py-2">
        <SidebarMenuList depth={depth + 1} pages={page.content} router={router} />
      </div>
    </SidebarMenuListItem>
  ) : (
    <SidebarMenuListItem
      depth={depth}
      href={page.to}
      icon={page.icon}
      key={page.to}
      label={page.badge}
      title={page.label}
    />
  );

  return [...items, newItem];
};
