import {FC, useCallback, useMemo} from 'react';

import {usePathname} from 'next/navigation';
import styled from 'styled-components';

import BlendTalkIcon from '@/src/components/GeneralComponents/Sidebar/components/BlendTalkIcon';
import ToggleIcon from '@/src/components/GeneralComponents/Sidebar/components/SidebarItemSmall/components/ToggleIcon';
import {MainMenuItem} from '@/src/components/GeneralComponents/Sidebar/SidebarUtils';

interface Props {
  item: MainMenuItem;
  userType: string;
  getMenuItemClasses: (key: string, to?: string) => string;
  onOpenClosedSidebar: (value: string) => void;
}

const SidebarItemSmall: FC<Props> = ({
  item: {userTypes, iconComponent, icon, key, to},
  userType,
  getMenuItemClasses,
  onOpenClosedSidebar,
}) => {
  const pathname = usePathname();

  const handleOpenClosedSidebar = useCallback(() => onOpenClosedSidebar(key), [onOpenClosedSidebar, key]);

  const isSelectedBlendTalk = useMemo(() => pathname.includes('blend-talk/chat'), [pathname]);

  const renderIcon =
    key === 'blendTalk' ? (
      <BlendTalkIcon selected={isSelectedBlendTalk} navigation />
    ) : (
      <ToggleIcon className={getMenuItemClasses(key, to)} key={key} icon={icon} onClick={handleOpenClosedSidebar} />
    );

  if (!userTypes || userTypes.includes(userType)) {
    return (
      <Wrapper>
        {iconComponent ? (
          <IconComponent key={key} className={getMenuItemClasses(key, to)} onClick={handleOpenClosedSidebar}>
            {iconComponent}
          </IconComponent>
        ) : (
          renderIcon
        )}
      </Wrapper>
    );
  }
  return null;
};

const Wrapper = styled.div`
  height: 54px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconComponent = styled.div`
  cursor: pointer;
  fill: ${({theme}) => theme.colors.grey090};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    padding: 10px;
    box-sizing: content-box;
  }

  &.active {
    svg {
      background-color: ${({theme}) => theme.colors.blue020};
      fill: ${({theme}) => theme.colors.blue100};
      border-radius: 10px;
    }
  }
`;

export default SidebarItemSmall;
