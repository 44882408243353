import {FC, useMemo} from 'react';

import {usePathname} from 'next/navigation';

import BlackLogo from '@/assets/icons_refactor/Common/blend-logo-black.svg';
import LightLogo from '@/assets/icons_refactor/Common/blend-logo-white.svg';
import BaseLink from '@/src/components/BaseComponents/BaseLink';
import useConfig from '@/src/hooks/useConfig';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';

interface Props {
  isWizard?: boolean;
  isDarkMode: boolean;
}

const HeaderLogo: FC<Props> = ({isWizard = false, isDarkMode}) => {
  const pathname = usePathname();
  const {appDomainURL = ''} = useConfig() || {};
  const {data: userData} = useGetUserDataQuery();

  const isLoggedIn: boolean = useMemo(() => !!userData?.id, [userData?.id]);

  const queryParams = pathname?.split('?')[1] || '';

  const logoUrl = `https://${isWizard ? (isLoggedIn ? appDomainURL : `blendexpress.com/${queryParams}`) : appDomainURL}`;

  return <BaseLink href={logoUrl}>{isDarkMode ? <LightLogo /> : <BlackLogo />}</BaseLink>;
};

export default HeaderLogo;
