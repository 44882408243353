import {FC} from 'react';

import styled from 'styled-components';

import BaseIcon from '@/src/components/BaseComponents/BaseIcon';

interface Props {
  className: string;
  icon?: FC;
  onClick: () => void;
}

const ToggleIcon: FC<Props> = ({onClick, className, icon}) => (
  <Wrapper className={className} icon={icon} onClick={onClick} boxH={20} boxW={24} />
);

const Wrapper = styled(BaseIcon)`
  cursor: pointer;
  fill: ${({theme}) => theme.colors.grey090};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background-color: ${({theme}) => theme.colors.blue020};
    fill: ${({theme}) => theme.colors.blue100};
    border-radius: 10px;
  }
`;

export default ToggleIcon;
