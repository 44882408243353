import {FC, ReactNode} from 'react';

import List from '@mui/material/List';
import {NextRouter} from 'next/router';

import {ChildPageRoutesProps, reduceChildRoutes} from '@/src/components/GeneralComponents/Sidebar/SidebarUtils';

interface Props {
  depth: number;
  pages: {items: ReactNode[]; page: ChildPageRoutesProps}[];
  router?: NextRouter;
  page?: ChildPageRoutesProps;
}

const SidebarMenuList: FC<Props> = ({pages, ...rest}) => (
  <List className="p-0">
    {pages.reduce((items, currentPage) => {
      const page = currentPage.page as ChildPageRoutesProps;
      return reduceChildRoutes({...rest, items, page});
    }, [] as ReactNode[])}
  </List>
);

export default SidebarMenuList;
