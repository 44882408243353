import {ChangeEvent, FC, useCallback, useMemo, useState} from 'react';

import styled from 'styled-components';

import Toggle from '@/assets/icons_refactor/Common/sidebar-toogle.svg';
import NoAvatar from '@/assets/icons_refactor/Header/avatar.svg';
import {switchBeneficiaryAccount} from '@/src/api/profile';
import BaseButton from '@/src/components/BaseComponents/BaseButton';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import BaseImage from '@/src/components/BaseComponents/BaseImage';
import BaseSingleSelect from '@/src/components/BaseComponents/BaseSingleSelect';
import useMobile from '@/src/hooks/useMobile';
import {useSession} from '@/src/hooks/useSession';
import useUserData from '@/src/hooks/useUserData';
import {useGetUserDataQuery} from '@/src/lib/store/stores/api';
import {boldWeight, font_large, font_medium} from '@/theme/fonts';

interface Props {
  isOpenSidebar: boolean;
  setSidebarToggle: (sidebarToggle: boolean) => void;
  hideLogoutBtn?: boolean;
  embedded?: boolean;
  isHidden: boolean;
}

const UserBar: FC<Props> = ({isOpenSidebar, setSidebarToggle, hideLogoutBtn, embedded, isHidden}) => {
  const {data: userData} = useGetUserDataQuery();

  const isLoggedIn: boolean = useMemo(() => !!userData?.id, [userData?.id]);

  const {
    billingAddress: {Company = ''} = {},
    thumb: {src = null} = {},
    name = 'Stranger',
    isAdmin = false,
    id: userID = '',
    statistics: {unreadMessages = 0} = {},
  } = userData ?? {};

  const {CSRFToken = ''} = useSession() || {};
  const {handleLogout} = useUserData();

  const {isMobile, isTablet} = useMobile();

  const [showBeneficiary, setShowBeneficiary] = useState<boolean>(false);

  const handleLogoutClick = useCallback(() => handleLogout(CSRFToken), [CSRFToken, handleLogout]);

  const handleToggleClick = useCallback(() => {
    setSidebarToggle?.(!isOpenSidebar);
  }, [isOpenSidebar]);

  const handleAvatarClick = () => {
    !isTablet && handleToggleClick();
  };

  const beneficiary = userData && Object.entries(userData.beneficiary);
  const beneficiaryAccounts = useMemo(
    () =>
      beneficiary?.map((item) => ({
        value: item[0],
        label: `Switch to: ${item[1]}`,
      })),
    [beneficiary]
  );

  const handleChange = useCallback(
    ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
      switchBeneficiaryAccount(CSRFToken, value).then(() =>
        typeof window !== undefined ? window.location.reload() : null
      );
    },
    [CSRFToken]
  );

  const handleOpenDropdown = useCallback(() => setShowBeneficiary(true), []);
  const handleCloseDropdown = useCallback(() => setShowBeneficiary(false), []);

  const messageText = useMemo(() => `${unreadMessages} Message${unreadMessages === 1 ? '' : 's'}`, [unreadMessages]);

  return (
    <Wrapper
      className="userbar"
      $embedded={+!!embedded}
      $ishidden={+(isHidden || !isOpenSidebar)}
      $istablet={+isTablet}
      $isopensidebar={+!isOpenSidebar}
    >
      <Menu>
        <Additional>
          <AvatarWrapper onClick={handleAvatarClick}>
            {src ? (
              <Avatar
                src={src}
                alt="avatar"
                $embedded={embedded ? 1 : 0}
                width={embedded ? 36 : 52}
                height={embedded ? 36 : 52}
              />
            ) : (
              <Guest icon={NoAvatar} boxW={37} boxH={37} $embedded={+!!embedded} />
            )}
          </AvatarWrapper>

          {isOpenSidebar && (
            <div>
              <Name
                data-qa-auto="hello-user"
                onPointerEnter={handleOpenDropdown}
                onClick={handleCloseDropdown}
                $embedded={embedded ? 1 : 0}
                $islogedin={+isLoggedIn}
              >
                Hello, {name?.slice(0, 10)}
                {beneficiaryAccounts?.length ? (
                  <BaseSingleSelect
                    isOpen={showBeneficiary}
                    options={beneficiaryAccounts}
                    onChange={handleChange}
                    dropdownClassname="switch-to-beneficiary"
                    value=""
                    variant="outlined"
                  />
                ) : null}
              </Name>
              {isLoggedIn && (
                <>
                  {!embedded && (
                    <CompanyName>
                      {Company?.slice(0, 10)} <span data-qa-auto="user-id">#{userID}</span>
                    </CompanyName>
                  )}
                  <ButtonsArea>
                    {isAdmin && (
                      <Button href="/admin" $embedded={+!!embedded}>
                        Admin
                      </Button>
                    )}
                    {embedded && isAdmin && <Separator>|</Separator>}
                    {(isMobile || embedded) && (
                      <MsgBtn $embedded={+!!embedded} href="/mbox">
                        {messageText}
                      </MsgBtn>
                    )}
                    {!hideLogoutBtn && (
                      <Button $embedded={0} onClick={handleLogoutClick}>
                        Logout
                      </Button>
                    )}
                  </ButtonsArea>
                </>
              )}
            </div>
          )}
        </Additional>
      </Menu>
      {isOpenSidebar && <ToggleIcon icon={Toggle} onClick={handleToggleClick} boxW={20} boxH={20} />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{$embedded: number; $ishidden: number; $istablet: number; $isopensidebar: number}>`
  height: ${({$embedded}) => ($embedded ? 57 : 104)}px;
  display: grid;
  grid-column-gap: 12px;
  grid-template-columns: auto ${({$isopensidebar}) => !$isopensidebar && '20px'};
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid ${({theme, $embedded}) => ($embedded ? 'none' : theme.colors.grey010)};
  position: fixed;
  top: 57px;
  width: fit-content;
  background-color: ${({theme}) => theme.colors.grey000};
  z-index: 10;
  max-width: 280px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: ${({$ishidden}) => ($ishidden ? 'none' : 'grid')};
  }
`;

const ButtonsArea = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-left: -10px;
`;

const Separator = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey000};
`;

const Button = styled(BaseButton)<{$embedded: number}>`
  ${font_medium};
  padding: 0;
  color: ${({
    theme: {
      colors: {grey085, grey000},
    },
    $embedded,
  }) => ($embedded ? grey000 : grey085)};

  &:hover {
    color: ${({theme}) => theme.colors.blue100};
  }

  &.MuiButtonBase-root {
    text-transform: capitalize;
    height: 24px;

    .MuiButton-label {
      ${font_medium};
      text-transform: capitalize;
    }
  }
`;

const Additional = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 25px;
  cursor: auto;
`;

const Menu = styled.div`
  cursor: pointer;
  display: grid;
  align-items: center;
`;

const Name = styled.div<{$embedded: number; $islogedin: number}>`
  ${({$embedded}) => ($embedded ? font_medium : font_large)};
  ${({$embedded}) => $embedded && boldWeight};
  color: ${({
    theme: {
      colors: {grey000, grey090},
    },
    $embedded,
  }) => ($embedded ? grey000 : grey090)};
  display: flex;
  align-items: center;
  width: 150px;
`;

const CompanyName = styled.div`
  ${font_medium};
  color: ${({theme}) => theme.colors.grey080};

  & > span {
    color: ${({theme}) => theme.colors.grey090};
  }
`;

const ToggleIcon = styled(BaseIcon)`
  cursor: pointer;
  fill: ${({theme}) => theme.colors.grey100};
  width: 20px;
  height: 20px;
  margin-top: 8px;

  @media ${({theme}) => theme.breakpoints.maxMd} {
    display: none;
  }
`;

const Guest = styled(BaseIcon)<{$embedded: number}>`
  width: ${({$embedded}) => ($embedded ? 36 : 52)}px;
  height: ${({$embedded}) => ($embedded ? 36 : 52)}px;

  svg {
    width: ${({$embedded}) => ($embedded ? 36 : 52)}px;
    height: ${({$embedded}) => ($embedded ? 36 : 52)}px;
    flex-shrink: 0;
  }
`;

const Avatar = styled(BaseImage)<{$embedded: number}>`
  width: ${({$embedded}) => ($embedded ? 36 : 52)}px;
  height: ${({$embedded}) => ($embedded ? 36 : 52)}px;
  border-radius: 26px;
`;

const MsgBtn = styled(Button)`
  margin: 0 10px;
`;

const AvatarWrapper = styled.div`
  @media ${({theme}) => theme.breakpoints.minMd} {
    cursor: pointer;
  }
`;

export default UserBar;
