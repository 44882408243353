import Select from '@mui/material/Select';
import styled, {createGlobalStyle} from 'styled-components';

import {font_large} from '@/theme/fonts';

export function CustomNavDropdown(props) {
  return (
    <>
      <SelectWrapper
        renderValue={() => props.title}
        displayEmpty
        IconComponent={() => <></>}
        MenuProps={{
          MenuListProps: {
            sx: styles,
          },
          TransitionProps: {
            className: 'header-nav-dropdown',
          },
        }}
      >
        {props.children}
      </SelectWrapper>
      <MenuStyle />
    </>
  );
}

const SelectWrapper = styled(Select)`
  &.MuiInputBase-root {
    color: ${({theme}) => theme.colors.grey000};
    ${font_large};
  }

  & .MuiSelect-select.MuiInputBase-input.MuiSelect-outlined {
    padding-right: 16px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &:hover {
    color: ${({theme}) => theme.colors.blue100};
  }

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const styles = {
  background: 'rgb(68, 68, 68)',
};

const MenuStyle = createGlobalStyle`
  .header-nav-dropdown {   
    width: 250px;
    padding: 0;
  }
`;
