import {ChatNotifications, UnreadNotification} from '../interfaces/types/blendTalk.types';

export const transformToChatNotifications = (
  unreadNotifications: UnreadNotification[],
  admin?: boolean
): ChatNotifications => {
  return unreadNotifications.reduce((chatNotifications, notification) => {
    const key = admin ? notification?.chat?.owner?.id : notification.chat?.id;
    if (key && !chatNotifications[key]) {
      chatNotifications[key] = [];
    }
    key && chatNotifications[key].push(notification);
    return chatNotifications;
  }, {} as ChatNotifications);
};
