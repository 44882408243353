import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ThemeOptionsState {
  isOpenSidebar: boolean;
}

const initialState: ThemeOptionsState = {
  isOpenSidebar: false,
};

const themeOptionsSlice = createSlice({
  name: 'themeOptions',
  initialState,
  reducers: {
    setSidebarToggle(state, action: PayloadAction<boolean>) {
      state.isOpenSidebar = action.payload;
    },
  },
});

export const {setSidebarToggle} = themeOptionsSlice.actions;

export default themeOptionsSlice.reducer;
