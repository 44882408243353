import React, {FC, useCallback, useEffect, useState} from 'react';

import {useRouter} from 'next/navigation';
import styled, {css} from 'styled-components';

import BlendTalk from '@/assets/icons_refactor/SideMenu/side-chat.svg';
import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import {useSession} from '@/src/hooks/useSession';
import {UnreadNotification} from '@/src/interfaces/types/blendTalk.types';
import {useGetBlendTalkNotificationsQuery} from '@/src/lib/store/stores/api';
import {transformToChatNotifications} from '@/src/utils/chatNotifications';
import {font_xsmall} from '@/theme/fonts';

interface BlendTalkIconProps {
  selected?: boolean;
  navigation?: boolean;
}

const BlendTalkIcon: FC<BlendTalkIconProps> = ({selected, navigation}) => {
  const {loggedInUserId = 0} = useSession() || {};
  const [notif, setNotif] = useState<UnreadNotification[]>([]);

  const {data: notifications = [], isSuccess} = useGetBlendTalkNotificationsQuery(undefined, {
    skip: !loggedInUserId && !!notif.length,
  });

  useEffect(() => {
    isSuccess && setNotif(notifications);
  }, [notifications, isSuccess]);

  const newNotifications = transformToChatNotifications(notifications);

  const {push} = useRouter();

  const handleClick = useCallback(() => navigation && push('/blend-talk/chat/'), [navigation]);

  const totalCount = Object.values(newNotifications).reduce(
    (total, notificationsArray) => total + notificationsArray.length,
    0
  );

  return (
    <Wrapper selected={selected ? 1 : 0} onClick={handleClick}>
      <Icon icon={BlendTalk} boxH={20} boxW={20} selected={selected ? 1 : 0} />
      {!!totalCount && <Counter>{totalCount >= 100 ? '99+' : totalCount}</Counter>}
    </Wrapper>
  );
};

const overflowStyles = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div<{selected: number}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${({
    selected,
    theme: {
      colors: {blue020, grey000},
    },
  }) => (selected ? blue020 : grey000)};
`;

const Counter = styled.div`
  ${font_xsmall};
  background-color: ${({theme}) => theme.colors.blue100};
  color: ${({theme}) => theme.colors.grey000};
  position: absolute;
  top: 3px;
  right: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${overflowStyles};
`;

const Icon = styled(BaseIcon)<{selected: number}>`
  fill: ${({
    selected,
    theme: {
      colors: {grey090, blue100},
    },
  }) => (selected ? blue100 : grey090)};
`;

export default BlendTalkIcon;
